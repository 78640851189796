<template>
  <v-container fluid class="sw-pt-10">
    <transition name="fade">
      <div v-if="isDataLoaded">
        <v-card flat max-width="800" class="mx-auto">
          <v-row class="no-gutters">
            <v-col cols="12">
              <div class="mb-4">{{ transformDate(message.created_at) }}</div>
              <div class="sw-page-title">
                {{ message.subject }}
              </div>
              <div v-html="message.body"></div>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </transition>
  </v-container>
</template>

<script>
import moment from "moment";

export default {
  data: () => ({
    isDataLoaded: false,
    message: {},
  }),
  computed: {
    userId() {
      return this.$store?.getters?.user?.id;
    },
    groupId() {
      return this.$store?.getters?.group?.id;
    },
    messageId() {
      return this.$route.params.message_id;
    },
    appLanguage() {
      return this.$store?.getters?.appLanguage;
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.getGroupMessage();
      },
    },
    appLanguage: {
      handler() {
        this.getGroupMessage();
      },
    },
  },
  methods: {
    getGroupMessage() {
      if (!this.groupId) return;

      this.$http
        .get(
          `/groups/${this.groupId}/users/${this.userId}/messages/${this.messageId}`,
          { params: { lang: this.appLanguage } },
        )
        .then(
          (response) => {
            this.isDataLoaded = true;

            if (!response?.data?.data) return;

            if (!response.data.data.last_read) {
              this.$http.get(`/auth/messages/${this.messageId}/touch`);
            }

            this.message = response.data.data;
          },
          () => {
            this.isDataLoaded = true;
          },
        );
    },
    transformDate(value) {
      return moment(value).format("DD-MM-YYYY - HH.MM");
    },
  },
};
</script>

<style scoped lang="scss"></style>
